<template>
    <div class="page h-full flex items-center justify-center">
        <div class="msg-container flex flex-col items-center gap-8">
            <img src="/img/warning-outline.svg" alt="error" class="w-16">
            <div class="text text-center">
                <p class="text-2xl font-bold">¡Ups!</p>
                <p>Eso que buscabas no está aquí</p>
            </div>
        </div>
    </div>
</template>